import React from "react";
import {
  Box,
  Button,
  Chip,
  Container,
  Divider,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import QrReader from "react-qr-reader";
import "./style.css";
import { useParams } from "react-router-dom";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../sql";
import beep1 from "../Assets/beep8.mp3";
import useSound from "use-sound";
import logo from "../Assets/eventegration.png";
import Page from "./Page";
import { IMAGES_API_URL } from "../constants";
import MatCarousel from "react-material-ui-carousel";
export default function ScanningAllRolesPage() {
  const {
    data: authData,
    error: authError,
    loading: authLoading,
  } = useQuery(GET_MY_AUTH);
  const { data: userData } = useQuery(GET_MY_USER);
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState({});
  const [error, setError] = React.useState(null);
  const [message, setMessage] = React.useState(null);
  const [playBeepSuccess] = useSound(beep1);
  const [playBeepFailed] = useSound(beep1, { playbackRate: 0.4 });
  const [ready, setReady] = React.useState(true);
  const eventId = authData?.eventId;
  // const handleScanResult = async (d) => {
  //   console.log(d)

  //   if (!d?.eventId || !d?.role || !d?.id) {
  //     try {
  //       playBeepSuccess();
  //       playBeepFailed();
  //     } catch (err) {}
  //     return;
  //   }
  //   if (d.eventId !== eventId) {
  //     try {
  //       playBeepFailed();
  //     } catch (err) {}

  //     setTimeout(() => {
  //       setLoading(false); // Set loading to false after 3 seconds
  //       setError(null);
  //     }, 3000);
  //     return setError("Invalid Event QR");
  //   }

  //   setLoading(true);
  //   setError(null);
  //   setMessage(null);
  //   try {
  //     const m = await uploadData({
  //       variables: {
  //         role: d.role,
  //         id: d.id,
  //       },
  //     });
  //     setData(d);
  //     // console.log(m);
  //     setMessage(m.data?.result || ""); // Make sure to access the result property correctly
  //     setTimeout(() => {
  //       setLoading(false); // Set loading to false after 3 seconds
  //       // console.log("run", loading);
  //       setMessage(null);
  //     }, 3000);
  //     setLoading(false);
  //     try {
  //       playBeepSuccess();
  //     } catch (err) {
  //       setLoading(false);
  //       console.log(err.message);
  //     }
  //   } catch (error) {
  //     setTimeout(() => {
  //       setLoading(false); // Set loading to false after 3 seconds
  //       setError(null);
  //     }, 3000);
  //   }
  // };

  const handleScanResult = async (d) => {
    console.log(d);

    // Parse `eventId`, `role`, and `id` if `d` contains vCard data
    let eventId = d.eventId;
    let role = d.role;
    let id = d.id;

    if (typeof eventId === "string" && eventId.includes("BEGIN:VCARD")) {
      // Extract the eventId, role, and id from the vCard format
      const eventIdMatch = eventId.match(/NOTE:UC:(\d+)/);
      const idMatch = id.match(/(\d+)/);

      if (eventIdMatch) eventId = eventIdMatch[1];
      if (idMatch) id = idMatch[1];
    }
console.log(eventId,role,id)
    // Check if we have valid eventId, role, and id
    if (!eventId || !role || !id) {
      try {
        playBeepSuccess();
        playBeepFailed();
      } catch (err) { }
      return;
    }

    // Compare the parsed or provided `eventId` with `authData.eventId`
    if (eventId !== authData?.eventId) {
      try {
        playBeepFailed();
      } catch (err) { }

      setTimeout(() => {
        setLoading(false);
        setError(null);
      }, 3000);
      return setError("Invalid Event QR");
    }

    setLoading(true);
    setError(null);
    setMessage(null);

    try {
      const m = await uploadData({
        variables: {
          role: role,
          id: id,
        },
      });

      setData({ eventId, role, id });
      setMessage(m.data?.result || "");

      setTimeout(() => {
        setLoading(false);
        setMessage(null);
      }, 3000);

      setLoading(false);

      try {
        playBeepSuccess();
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      }
    } catch (error) {
      setTimeout(() => {
        setLoading(false);
        setError(null);
      }, 3000);
    }
  };

  const handleScan = async (d) => {
    if (loading || !ready) return;
    setReady(false);
    await handleScanResult(d);
    await new Promise((res) => setTimeout(res, 5000));
    setReady(true);
  };
  const handleError = (e) => {
    setError(e.message);
    setMessage(null);
  };
  // console.log(`${IMAGES_API_URL}sponsor/${eventId}.jpg`);
  const [uploadData] = useMutation(UPLOAD_SCAN_RESULT, {
    onError: handleError,
  });
  // const img = `${IMAGES_API_URL}sponsor/${eventId}.jpg`;
  // console.log(img);
  const img = `${IMAGES_API_URL}sponsor/${eventId}.jpg`;
  const [imageUrl, setImageUrl] = React.useState(true);

  // const checkImageExists = async (url) => {
  //   try {
  //     const response = await fetch(url, {
  //       method: "HEAD",
  //     });
  //     return response.ok;
  //   } catch (error) {
  //     console.log(error.message);
  //     return false;
  //   }
  // };

  // Usage in your component
  // const [imageExists, setImageExists] = React.useState(false);

  // React.useEffect(() => {
  //   const fetchImage = async () => {
  //     const exists = await checkImageExists(imageUrl);
  //     setImageExists(exists);
  //   };

  //   fetchImage();
  // }, [imageUrl]);
  const handleImageError = () => {
    setImageUrl(false);
  };

  return (
    <Page title="Badge Scanner">
      <Typography
        variant="h4"
        fontSize={20}
        textAlign={"center"}
        gutterBottom
        align="center"
        py={1}
      >
        {/* {authData?.direction
            ?.replace("ENTRY_SCAN", "Entrance")
            .replace("EXIT_SCAN", "Exit")}{" "} */}
        {userData?.getUserDetail} Scanner
      </Typography>
      {/* <Typography variant="p" fontSize={16} gutterBottom align="center">
          {userData?.getUserDetail}
        </Typography> */}

      {authLoading ? (
        <LinearProgress />
      ) : !!authError ? (
        <Typography color="error">
          {JSON.stringify(authError, null, 2)}
        </Typography>
      ) : authData?.eventId ? (
        <ScanAndUpload
          eventId={authData.eventId}
          handleScan={handleScan}
          handleError={handleError}
          loading={loading}
          error={error}
          setLoading={setLoading}
          message={message}
          data={data}
        />
      ) : (
        ""
      )}
      <Box mt={0} sx={{ textAlign: "center" }}>
        {/* {imageUrl && ( */}
        <img
          src={`${IMAGES_API_URL}sponsor/${eventId}.jpg`}
          width="100%"
          alt="Sponsor"
          onError={() => handleImageError()}
        />
        {/* )} */}
      </Box>
      <Box px={4} mt={1} sx={{ textAlign: "center" }}>
        <Typography align="center" fontSize={10} gutterBottom>
          Powered by...
        </Typography>
        <img src={logo} width="60%" alt="Logo" />
      </Box>
    </Page>
  );
}

function ScanAndUpload({
  eventId,
  handleScan,
  handleError,
  setLoading,
  loading,
  error,
  message,
  data,
}) {
  // console.log(loading, "loading");

  return (
    <>
      <Stack spacing={2} sx={{ position: "relative" }}>
        <Scanner
          onScan={handleScan}
          onError={handleError}
          error={error}
          message={message}
        />

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            position: "absolute",
            top: "0",
            bottom: "0",
            left: "0",
            right: "0",
            zIndex: 5,
          }}
        >
          {!!loading ? (
            <LinearProgress />
          ) : error ? (
            <Stack spacing={3}>
              <Chip
                color={
                  ["approved", "granted", "success", "allowed"].find(
                    (x) => !!error.toLowerCase().includes(x)
                  )
                    ? "success"
                    : "error"
                }
                label={error}
                size="large"
                sx={{ fontSize: 20, p: 3 }}
              />
              <Typography variant="h4" align="center">
                {data?.id}
              </Typography>
            </Stack>
          ) : message ? (
            <Stack spacing={3}>
              <Chip
                color={
                  ["approved", "granted", "success", "allowed"].find(
                    (x) => !!message.toLowerCase().includes(x)
                  )
                    ? "success"
                    : "error"
                }
                label={message}
                size="large"
                sx={{ fontSize: 20, p: 3 }}
              />
              <Typography variant="h4" align="center">
                {data?.id}
              </Typography>
            </Stack>
          ) : (
            ""
          )}
        </Box>
      </Stack>
    </>
  );
}

function Scanner({ onScan, onError, error, message }) {
  const handleResult = async (result, error) => {
    try {
      if (!!result) {
        const t = result;
        // console.log(t);
        if (!t) {
          throw error || new Error("Invalid QR");
        }
        const [eventId, role, id] = t.split(",");
        if (!eventId || !role || !id) {
          throw new Error("Invalid QR");
        }
        await onScan({ eventId, role, id });
      } else {
        console.log("Not Found");
      }
    } catch (e) {
      console.log(e.message);
      onError(e);
    }
  };
  // console.log("first", error, message);
  return (
    <>
      <div className="scanner-container">
        {message === null && error === null && (
          <div className="scanner-animation" />
        )}
        <QrReader
          constraints={{
            facingMode: "environment",
          }}
          delay={1500}
          onScan={handleResult}
          onError={onError}
          showViewFinder={true}
          style={{
            color: "white",
            zIndex: 100,
            boxSizing: "border-box",
            border: "5px solid rgba(0, 0, 0, 0.3)",
            width: "100%",
            height: "100%",
            padding: "5px",
            "& video": { padding: "5px" },
            "& div": {
              boxShadow: "rgb(13 111 220) 0px 0px 0px 5px inset",
            },
            background:
              "linear-gradient(to right, white 4px, transparent 4px) 0 0," +
              "linear-gradient(to right, white 4px, transparent 4px) 0 100%," +
              "linear-gradient(to left, white 4px, transparent 4px) 100% 0," +
              "linear-gradient(to left, white 4px, transparent 4px) 100% 100%," +
              "linear-gradient(to bottom, white 4px, transparent 4px) 0 0," +
              "linear-gradient(to bottom, white 4px, transparent 4px) 100% 0," +
              "linear-gradient(to top, white 4px, transparent 4px) 0 100%," +
              "linear-gradient(to top, white 4px, transparent 4px) 100% 100%",
            backgroundRepeat: "no-repeat",
            backgroundSize: "20px 20px",
          }}
        />
      </div>
    </>
  );
}
// function SupportersSection() {
//   const width = 175; //200
//   const height = 125; //150
//   const [homeSponsors, setHomeSponsors] = React.useState([]);
//   React.useEffect(() => {
//     const t = setTimeout(async () => {
//       const sponsors = await Client.getSponsors();
//       if (sponsors?.length) {
//         setHomeSponsors(arrayTo2D(sponsors, 2));
//         // const r = makeArray([..."123456789"], 4);
//         // console.log(r);
//       }
//     }, 1000);
//     return () => {
//       clearTimeout(t);
//     };
//   }, []);

//   return (
//     <Box
//       sx={{
//         py: 6,
//       }}
//     >
//       <Container maxWidth="xl">
//         <Typography variant="h3" fontSize={24} sx={{ pb: 2 }}>
//           SPONSORS & PARTNERS
//         </Typography>
//         <MatCarousel interval={7500}>
//           {homeSponsors?.map((a, i) => (
//             <Stack
//               direction="row"
//               key={i}
//               spacing={2}
//               justifyContent={"space-evenly"}
//               py={3}
//             >
//               {a.length >= 1 && (
//                 <a href={a[0].link} target="_blank">
//                   <img alt="" src={a[0].path} width={width} height={height} />
//                 </a>
//               )}
//               {a.length >= 2 && (
//                 <a href={a[1].link} target="_blank">
//                   <img alt="" src={a[1].path} width={width} height={height} />
//                 </a>
//               )}
//               {a.length >= 3 && (
//                 <a href={a[2].link} target="_blank">
//                   <img alt="" src={a[2].path} width={width} height={height} />
//                 </a>
//               )}
//               {a.length >= 4 && (
//                 <a href={a[3].link} target="_blank">
//                   <img alt="" src={a[3].path} width={width} height={height} />
//                 </a>
//               )}
//             </Stack>
//           ))}
//         </MatCarousel>
//       </Container>
//     </Box>
//   );
// }
const GET_MY_AUTH = gql`
  query {
    eventId
    staffId
    direction
  }
`;
const GET_MY_USER = gql`
  query {
    getUserDetail
  }
`;

const UPLOAD_SCAN_RESULT = gql`
  mutation ($role: String!, $id: String!) {
    result: uploadScanResult(role: $role, id: $id)
  }
`;
